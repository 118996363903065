import { string, date, object } from 'yup';

const today = new Date();

export const FIELDS_TO_TRIM = ['note'];

export const initialValues = {
  note: '',
  scheduled_date: '',
  follow_up_date: '',
};

export const validationSchema = object().shape({
  note: string(),
  scheduled_date: date()
    .min(today, 'Date must be no ealier than today')
    .required('Engagement Scheduled Date is required'),
  follow_up_date: date().min(today, 'Date must be no ealier than today'),
});
