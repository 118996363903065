const styles = () => ({
  note: {
    fontWeight: 'bold',
    marginTop: '35px',
  },
  engagementCompletedDate: {
    marginBottom: '20px',
  },
});

export default styles;
