import { toUpper, camelCase, snakeCase } from 'lodash';

export const closeModal = modalName => ({
  type: `CLOSE_${toUpper(snakeCase(modalName))}_MODAL`,
  modalName,
});

export const openModal = ({ modalName, id }) => ({
  type: `OPEN_${toUpper(snakeCase(modalName))}_MODAL`,
  modalName,
  id,
});

const INITIAL_STATE = {
  approve: {
    isOpen: false,
    id: '',
  },
  followUp: {
    isOpen: false,
    id: '',
  },
  reject: {
    isOpen: false,
    id: '',
  },
  schedule: {
    isOpen: false,
    id: '',
  },
  complete: {
    isOpen: false,
    id: '',
  },
  deleteRequest: {
    isOpen: false,
    id: '',
  },
  addOrEditUser: {
    isOpen: false,
    id: '',
  },
  deleteUser: {
    isOpen: false,
    id: '',
  },
  checkBillingStatus: {
    isOpen: false,
    id: '',
  },
  processAPICall: {
    isOpen: false,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (true) {
    case /CLOSE_[A-Z_]+_MODAL$/.test(action.type):
      return {
        ...state,
        [action.modalName]: {
          isOpen: false,
          id: '',
        },
      };
    case /OPEN_[A-Z_]+_MODAL$/.test(action.type):
      return {
        ...state,
        [action.modalName]: {
          isOpen: true,
          id: action.id,
        },
      };
    case /CREATE_[A-Z_]+_ENTITY_REQUEST$/.test(action.type):
    case /UPDATE_[A-Z_]+_ENTITY_REQUEST$/.test(action.type):
    case /DELETE_[A-Z_]+_ENTITY_REQUEST$/.test(action.type):
      return {
        ...state,
        processAPICall: {
          isOpen: true,
          requestName: camelCase(action.type.substring(0, action.type.lastIndexOf('_REQUEST'))),
        },
      };
    default:
      return state;
  }
};

export default reducer;
