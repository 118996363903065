import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import FormikTextField from '../FormikTextField';

const FormikDropDown = (props) => {
  const { options, ...others } = props;
  return (
    <FormikTextField select {...others}>
      {options
        && options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
    </FormikTextField>
  );
};

FormikDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default FormikDropDown;
