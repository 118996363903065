import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Typography } from '@material-ui/core';

import { LOGIN_ENDPOINT } from '../../config';
import styles from './styles';

const LandingPage = ({ classes }) => (
  <div style={{ padding: '98px 24px 24px 24px', width: '100%' }}>
    <div className={classes.content}>
      <Typography variant="h6">Please log in</Typography>
      <a style={{ textDecoration: 'none' }} href={LOGIN_ENDPOINT}>
        <Button variant="contained" color="primary">
          Log in
        </Button>
      </a>
    </div>
  </div>
);

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPage);
