import { snakeCase, toUpper, omit } from 'lodash';

function pushEntities(state, action) {
  const Constructor = Object;
  return {
    ...state,
    byId: {
      ...state.byId,
      ...Object.keys(action.entitiesKeyedById).reduce((newEntities, entityId) => {
        const entity = new Constructor({
          ...newEntities[entityId],
        });

        return { ...newEntities, [entityId]: entity };
      }, action.entitiesKeyedById),
    },
  };
}

export default function createReducer(entityType) {
  const initialState = {
    byId: {},
  };

  /**
   * @param {Object} state
   * @param {Object} action
   */
  return (state = initialState, action) => {
    switch (action.type) {
      case `ADD_${toUpper(snakeCase(entityType))}_ENTITIES`:
        return pushEntities(state, action);
      case `REMOVE_${toUpper(snakeCase(entityType))}_ENTITY`:
        return {
          ...state,
          byId: omit(state.byId, action.entityId),
        };
      default:
        return state;
    }
  };
}
