import { string, date, object } from 'yup';

const today = new Date();

export const initialValues = {
  note: '',
  scheduled_date: '',
  follow_up_date: '',
};

export const FIELDS_TO_TRIM = ['note'];

export const validationSchema = object().shape(
  {
    note: string(),
    scheduled_date: date()
      .min(today, 'Date and time cannot be ealier than now')
      .when('follow_up_date', (follow_up_date, schema) => (follow_up_date ? schema : schema.required('At least one of the date fields is required'))),
    follow_up_date: date()
      .min(today, 'Date and time cannot be ealier than now')
      .when('scheduled_date', (scheduled_date, schema) => (scheduled_date ? schema : schema.required('At least one of the date fields is required'))),
  },
  ['scheduled_date', 'follow_up_date'],
);
