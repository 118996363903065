import { connect } from 'react-redux';
import { values } from 'lodash';

import { CRUD_REQUESTS } from '../../config';
import { fetchMany } from '../../modules/entities';
import Requests from './Requests';

const formatRequest = (request) => {
  const { _id } = request;
  const lastFourOfId = _id.slice(-4);
  request.location = request.location_internal || request.location;
  return { ...request, lastFourOfId };
};

const mapStateToProps = (state) => {
  const areRequestsLoading = state.loading.fetchRequestsEntities;

  let approvedRequests = [];
  let newRequests = [];
  if (areRequestsLoading || areRequestsLoading) {
    approvedRequests = null;
    newRequests = null;
  } else {
    values(state.entities.requests.byId).forEach((request) => {
      const { status } = request;
      const updatedReq = formatRequest(request);
      if (status === 'new') {
        newRequests.push(updatedReq);
      } else if (status === 'approved') {
        approvedRequests.push(updatedReq);
      }
    });
  }
  const error = state.error.fetchRequestsEntities;
  return {
    newRequests,
    approvedRequests,
    areRequestsLoading,
    error,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRequests: () => dispatch(
    fetchMany({
      entityType: 'requests',
      entityUrl: CRUD_REQUESTS,
    }),
  ),
});

const RequestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Requests);

export default RequestsContainer;
