import { snakeCase, toUpper } from 'lodash';

export function entityActionCreator(entityResponse) {
  return entityResponse.entities
    ? Object.keys(entityResponse.entities)
      .map(entityType => ({
        type: `ADD_${toUpper(snakeCase(entityType))}_ENTITIES`,
        entityType,
        entitiesKeyedById: entityResponse.entities[entityType],
      }))
      .filter(action => !!action)
    : [];
}

export function fetchOne({ entityType, entityId, entityUrl }) {
  return {
    type: `FETCH_${toUpper(snakeCase(entityType))}_ENTITY_REQUEST`,
    entityType,
    entityUrl,
    entityId,
  };
}

export function fetchMany({ entityType, entityUrl }) {
  return {
    type: `FETCH_${toUpper(snakeCase(entityType))}_ENTITIES_REQUEST`,
    entityType,
    entityUrl,
  };
}

export function updateOne({
  entityUrl, entityType, entityData, entityId,
}) {
  return {
    type: `UPDATE_${toUpper(snakeCase(entityType))}_ENTITY_REQUEST`,
    entityUrl,
    entityType,
    entityData,
    entityId,
  };
}

export function createOne({ entityUrl, entityType, entityData }) {
  return {
    type: `CREATE_${toUpper(snakeCase(`${entityType}`))}_ENTITY_REQUEST`,
    entityUrl,
    entityType,
    entityData,
  };
}

export function deleteOne({ entityUrl, entityType, entityId }) {
  return {
    type: `DELETE_${toUpper(snakeCase(entityType))}_ENTITY_REQUEST`,
    entityUrl,
    entityType,
    entityId,
  };
}

export function removeOne({ entityType, entityId }) {
  return {
    type: `REMOVE_${toUpper(snakeCase(entityType))}_ENTITY`,
    entityType,
    entityId,
  };
}

export function requestSuccess({ request, response }) {
  return {
    type: `${request.substring(0, request.lastIndexOf('_REQUEST'))}_SUCCESS`,
    response,
  };
}
export function requestFailure({ request, error }) {
  return {
    type: `${request.substring(0, request.lastIndexOf('_REQUEST'))}_FAILURE`,
    error,
  };
}
