import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ErrorIcon } from '../Icons';

class CheckBillingModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { checkBillingStatus, isOpen } = this.props;
    if (!prevProps.isOpen && isOpen) {
      checkBillingStatus();
    }
  }

  render() {
    const {
      isOpen, handleClose, response, isLoading, error,
    } = this.props;
    const renderHelper = () => {
      if (error) {
        return (
          <React.Fragment>
            <Typography variant="h6">{error}</Typography>
            <ErrorIcon />
          </React.Fragment>
        );
      }
      if (isLoading || !response) {
        return <CircularProgress size={200} />;
      }
      if (response.state === 'rated') {
        return <Typography variant="h6">Billing has been successful!</Typography>;
      }
      return (
        <React.Fragment>
          <Typography variant="h6">
            {`The billing is status is ${response.state} (code ${response.code}).`}
          </Typography>
          <Typography>Please contact Yin Chen at yinchen@us.ibm.com for support.</Typography>
        </React.Fragment>
      );
    };

    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Billing Status</DialogTitle>
        <DialogContent>{renderHelper()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CheckBillingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  response: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  checkBillingStatus: PropTypes.func.isRequired,
};

CheckBillingModal.defaultProps = {
  isLoading: false,
  error: '',
  response: '',
};

export default CheckBillingModal;
