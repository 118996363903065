const styles = theme => ({
  listItemTextSummary: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 0,
  },
  padding: {
    paddingLeft: theme.spacing.unit * 6,
  },
  header: {
    marginBottom: theme.spacing.unit,
  },
  breakWords: {
    wordBreak: 'break-word',
  },
  rowBackgroundColor: {
    display: 'flex',
    backgroundColor: '#f5f7fa',
  },
  tableRowContainer: {
    display: 'flex',
    backgroundColor: '#f5f7fa',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexWrap: 'wrap',
    width: '100%',
    minHeight: theme.spacing.unit * 6,
    paddingLeft: theme.spacing.unit * 6,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  'MuiDialog-paperWidthMd': {
    maxWidth: '100%',
  }
});

export default styles;
