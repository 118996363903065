import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, AppBar, Toolbar, Typography,
} from '@material-ui/core';

import styles from './styles';

const Header = ({ classes }) => (
  <AppBar position="fixed" className={classes.header}>
    <Toolbar className={classes.toolBar}>
      <Typography variant="h6" color="textPrimary">
        IBM Technology Expert Labs Management Console
      </Typography>
    </Toolbar>
  </AppBar>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
