import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'typeface-roboto';

import { store, history } from './store';
import {
  REQUESTS_ENDPOINT,
  ARCHIVED_REQUESTS_ENDPOINT,
  ADMIN_SETTINGS_ENDPOINT,
  LANDING_ENDPOINT,
} from './config';
import './static_assets/index.css';
import theme from './utils/theme';
import Header from './components/Header';
import ApproveModal from './components/ApproveModal';
import FollowUpModal from './components/FollowUpModal';
import RejectModal from './components/RejectModal';
import ScheduleModal from './components/ScheduleModal';
import CompleteModal from './components/CompleteModal';
import DeleteRequestModal from './components/DeleteRequestModal';
import AddOrEditUserModal from './components/AddOrEditUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import ProcessAPICallModal from './components/ProcessAPICallModal';
import CheckBillingModal from './components/CheckBillingModal';
import PageNotFound from './pages/PageNotFound';
import ArchivedRequests from './pages/ArchivedRequests';
import Requests from './pages/Requests';
import AdminSettings from './pages/AdminSettings';
import LandingPage from './pages/LandingPage';

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <Header />
            <Switch>
              <Route exact path={LANDING_ENDPOINT} component={LandingPage} />
              <Route exact path={REQUESTS_ENDPOINT} component={Requests} />
              <Route exact path={ARCHIVED_REQUESTS_ENDPOINT} component={ArchivedRequests} />
              <Route exact path={ADMIN_SETTINGS_ENDPOINT} component={AdminSettings} />
              <Route component={PageNotFound} />
            </Switch>
            <ApproveModal />
            <FollowUpModal />
            <RejectModal />
            <ScheduleModal />
            <CompleteModal />
            <DeleteRequestModal />
            <AddOrEditUserModal />
            <DeleteUserModal />
            <ProcessAPICallModal />
            <CheckBillingModal />
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);
