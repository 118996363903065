import {
  takeEvery, call, put, all,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  entityActionCreator, requestFailure, requestSuccess, removeOne,
} from './actions';
import * as api from '../../../utils/api';
import { LANDING_ENDPOINT } from '../../../config';
import { logout } from '../../authentication';

export function* workerHandleError({ request, errorResponse }) {
  yield put(
    requestFailure({
      request,
      error: (!!errorResponse && errorResponse.data) || 'Unexpected error',
    }),
  );
  if (errorResponse.status === 401) {
    yield put(logout());
    yield put(push(LANDING_ENDPOINT));
  }
}

export function* workerFetchOne(action) {
  try {
    const {
      entityType, entityUrl, entityId, type,
    } = action;
    const res = yield call(
      api.fetchOne,
      entityType,
      entityId ? `${entityUrl}/${entityId}` : entityUrl,
    );
    yield all(entityActionCreator(res).map(resAction => put(resAction)));
    yield put(requestSuccess({ request: type }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

export function* workerFetchMany(action) {
  try {
    const { entityType, entityUrl, type } = action;
    const res = yield call(api.fetchMany, entityType, entityUrl);
    yield all(entityActionCreator(res).map(resAction => put(resAction)));
    yield put(requestSuccess({ request: type }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

export function* workerUpdateOne(action) {
  try {
    const {
      entityType, entityUrl, entityId, type, entityData,
    } = action;
    const res = yield call(
      api.updateOne,
      entityType,
      entityId ? `${entityUrl}/${entityId}` : entityUrl,
      entityData,
    );
    yield all(entityActionCreator(res).map(resAction => put(resAction)));
    yield put(requestSuccess({ request: type }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

export function* workerCreateOne(action) {
  try {
    const {
      entityType, entityUrl, entityData, type,
    } = action;
    const res = yield call(api.createOne, entityType, entityUrl, entityData);
    yield all(entityActionCreator(res).map(resAction => put(resAction)));
    yield put(requestSuccess({ request: type }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

export function* workerDeleteOne(action) {
  try {
    const {
      entityType, entityUrl, entityId, type,
    } = action;
    yield call(api.deleteOne, entityType, entityUrl);
    yield put(requestSuccess({ request: type }));
    yield put(removeOne({ entityType, entityId }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

// Watcher root saga
export default function* entitiesSaga() {
  yield all([
    takeEvery(action => /FETCH_[A-Z_]+_ENTITY_REQUEST/.test(action.type), workerFetchOne),
    takeEvery(action => /FETCH_[A-Z_]+_ENTITIES_REQUEST/.test(action.type), workerFetchMany),
    takeEvery(action => /CREATE_[A-Z_]+_ENTITY_REQUEST/.test(action.type), workerCreateOne),
    takeEvery(action => /UPDATE_[A-Z_]+_ENTITY_REQUEST/.test(action.type), workerUpdateOne),
    takeEvery(action => /DELETE_[A-Z_]+_ENTITY_REQUEST/.test(action.type), workerDeleteOne),
  ]);
}
