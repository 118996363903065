import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const MainContent = ({ classes, children }) => (
  <main className={classes.content}>
    <div className={classes.toolbar} />
    {children}
  </main>
);

MainContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default withStyles(styles)(MainContent);
