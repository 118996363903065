import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import { Field } from 'formik';

const WrappedMuiDateField = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: {
    touched, errors, setFieldValue, setFieldTouched,
  }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onBlur,
  onChange,
  ...others
}) => (
  <DateTimePicker
    variant="filled"
    name={name}
    value={value || null}
    helperText={touched[name] && !!errors[name] ? errors[name] : ''}
    error={touched[name] && !!errors[name]}
    onChange={(date) => {
      setFieldValue(name, moment(date));
      setFieldTouched(name, true);
    }}
    onClose={() => {
      setFieldTouched(name, true);
    }}
    initialFocusedDate={null}
    {...others}
  />
);

const FormikDateField = ({ name, ...others }) => (
  <Field name={name} component={WrappedMuiDateField} {...others} />
);

WrappedMuiDateField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

WrappedMuiDateField.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
};

FormikDateField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikDateField;
