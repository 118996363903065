import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { pick } from 'lodash';

import {
  FormikTextField,
  FormikDropDown,
  FormikMultipleSelect,
  FormikCheckbox,
} from '../FormikElements';
import { validationSchema, FIELDS_TO_TRIM } from './formConfig';
import {
  teamDropdownOptions,
  offeringsDropdownOptions,
  locationsDropdownOptions,
} from '../../config';
import { trimValues } from '../../utils/helpers';

class AddOrEditUserModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen,
      handleClose,
      createOrUpdateUser,
      isAPICallModalOpen,
      initialValues,
    } = this.props;
    return (
      <Dialog
        keepMounted={isOpen}
        open={isOpen && !isAPICallModalOpen}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => createOrUpdateUser({
            ...values,
            products: values.products.slice(0).sort(),
            locations: values.locations.slice(0).sort(),
            ...trimValues(pick(values, FIELDS_TO_TRIM)),
          })}
        >
          <Form>
            <DialogTitle>Add/Edit Team Member</DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Typography>
                    Please add team members carefully as email notifications will be sent to them
                    depending on the information provided below
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField name="firstName" fullWidth label="First Name" />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField name="lastName" fullWidth label="Last Name" />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField name="email" fullWidth label="Email Address" />
                </Grid>
                <Grid item xs={6}>
                  <FormikDropDown
                    options={teamDropdownOptions}
                    name="team"
                    label="Team"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikMultipleSelect
                    options={offeringsDropdownOptions}
                    name="products"
                    label="Offerings"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikMultipleSelect
                    options={locationsDropdownOptions}
                    name="locations"
                    label="Locations"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name="suppress_emails" label="Suppress Emails" />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name="will_receive_bss_failure_emails" label="Will receive BSS status emails" />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  }
}

AddOrEditUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  createOrUpdateUser: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

AddOrEditUserModal.defaultProps = {
  isLoading: false,
  error: '',
};

export default AddOrEditUserModal;
