import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { getOverflowMenuOptions } from '../../config';

class OverflowMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleMenuOptionClick(modalName) {
    const { openModal, id } = this.props;
    return () => {
      openModal({ modalName, id });
      this.setState({ anchorEl: null });
    };
  }

  render() {
    const { anchorEl } = this.state;
    const { tableType, request } = this.props;
    return (
      <React.Fragment>
        <IconButton onClick={this.handleClick}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {getOverflowMenuOptions({ tableType, request }).map(({ id, label }) => (
            <MenuItem key={id} onClick={this.handleMenuOptionClick(id)}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

OverflowMenu.propTypes = {
  openModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  tableType: PropTypes.string.isRequired,
  request: PropTypes.object,
};

OverflowMenu.defaultProps = {
  request: null,
};

export default OverflowMenu;
