import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideBar from './SideBar';
import { getDataToExport } from '../../selectors';

const mapStateToProps = state => ({
  dataToExport: getDataToExport(state),
});

const SideBarContainer = connect(
  mapStateToProps,
)(SideBar);

export default withRouter(SideBarContainer);
