import palette from './theme.palette';

const { secondary, common, error } = palette;

const styles = {
  containedPrimary: {
    backgroundColor: secondary.main,
    color: common.white,
    '&:hover': {
      backgroundColor: secondary.main,
    },
  },
  containedSecondary: {
    backgroundColor: error.main,
    color: common.white,
    '&:hover': {
      backgroundColor: error.main,
    },
  },
};

export default styles;
