const styles = () => ({
  drawer: {
    width: '170px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '170px',
  },
  toolbar: {
    minHeight: '50px',
  },
  listItemText: {
    padding: 0,
  },
});

export default styles;
