import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  FilledInput,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Chip,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { Field } from 'formik';
import { offeringsDropdownOptions, locationsDropdownOptions } from '../../../config';

import styles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const WrappedMultipleSelect = ({
  field: { name, value, ...otherFieldProps }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  classes,
  disabled,
  options,
  label,
}) => (
  <FormControl
    disabled={disabled}
    className={classes.formControl}
    error={!!errors[name] && !!touched[name]}
  >
    <InputLabel htmlFor={name} variant="filled">
      {label}
    </InputLabel>
    <Select
      multiple
      value={value || []}
      classes={{ icon: disabled ? classes.hideIcon : '' }}
      input={
        disabled ? (
          <Input disableUnderline fullWidth id={name} name={name} />
        ) : (
          <FilledInput fullWidth id={name} name={name} />
        )
      }
      renderValue={selected => (
        <div className={classes.chips}>
          {selected.map((selectedValue) => {
            if (
              offeringsDropdownOptions.some(
                (item) =>
                  item.label === selectedValue || item.id === selectedValue
              ) ||
              locationsDropdownOptions.some(
                (item) =>
                  item.label === selectedValue || item.id === selectedValue
              )
            )
              return (
                <Chip
                  color="secondary"
                  key={selectedValue}
                  label={selectedValue}
                  className={classes.chip}
                />
              );
          })}
        </div>
      )}
      MenuProps={MenuProps}
      {...otherFieldProps}
    >
      {options
        && options.map(option => (
          <MenuItem
            key={option.id}
            value={option.id}
            classes={{ root: classes.rootSelected, selected: classes.selected }}
          >
            <Checkbox color="secondary" checked={value.indexOf(option.id) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
    </Select>
    {errors[name] && touched[name] && <FormHelperText>{errors[name]}</FormHelperText>}
  </FormControl>
);

WrappedMultipleSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
};

WrappedMultipleSelect.defaultProps = {
  disabled: false,
  label: '',
  options: null,
};

const FormikMultipleSelect = ({ name, ...others }) => (
  <Field name={name} component={WrappedMultipleSelect} {...others} />
);

FormikMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

FormikMultipleSelect.defaultProps = {
  disabled: false,
  options: null,
};

export default withStyles(styles)(FormikMultipleSelect);
