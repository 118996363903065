import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Typography, Paper, Divider, IconButton,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import styles from './styles';

const TableHeader = ({
  header, classes, order, onRequestSort,
}) => (
  <Paper square>
    <div className={classes.tableHeader}>
      {header.map(({
        width, label, canSort, id,
      }) => (
        <div key={id} className={classes.cell} style={{ width }}>
          <Typography variant="subtitle2" className={classes.label}>{label}</Typography>
          {canSort && (
            <IconButton onClick={() => onRequestSort(id)} className={classes.sortingIcon}>
              {order[id] === 'desc' ? (
                <ArrowDownward fontSize="small" />
              ) : (
                <ArrowUpward fontSize="small" />
              )}
            </IconButton>
          )}
        </div>
      ))}
    </div>
    <Divider />
  </Paper>
);

TableHeader.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  order: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableHeader);
