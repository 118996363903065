import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Drawer, List, ListItem, ListItemText, Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  LOGOUT_ENDPOINT,
  REQUESTS_ENDPOINT,
  ARCHIVED_REQUESTS_ENDPOINT,
  ADMIN_SETTINGS_ENDPOINT,
  CSV_HEADERS,
} from '../../config';
import styles from './styles';
import { arraysToCSV } from '../../utils/helpers';

const SideBar = ({ classes, history: { location }, dataToExport }) => (
  <Drawer
    className={classes.drawer}
    variant="permanent"
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <div className={classes.toolbar} />
    <List>
      <Link style={{ textDecoration: 'none' }} to={REQUESTS_ENDPOINT}>
        <ListItem button selected={location.pathname === REQUESTS_ENDPOINT} style={{ color: 'black' }}>
          <ListItemText className={classes.listItemText} primary="Requests" />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none' }} to={ARCHIVED_REQUESTS_ENDPOINT}>
        <ListItem button selected={location.pathname === ARCHIVED_REQUESTS_ENDPOINT} style={{ color: 'black' }}>
          <ListItemText className={classes.listItemText} primary="Archived Requests" />
        </ListItem>
      </Link>
      <Link style={{ textDecoration: 'none' }} to={ADMIN_SETTINGS_ENDPOINT}>
        <ListItem button selected={location.pathname === ADMIN_SETTINGS_ENDPOINT} style={{ color: 'black' }}>
          <ListItemText className={classes.listItemText} primary="Admin Settings" />
        </ListItem>
      </Link>
      <Divider />
      <ListItem
        button
        component="a"
        download="requests.csv"
        target="_self"
        href={encodeURI(arraysToCSV(dataToExport, CSV_HEADERS))}
        style={{ color: 'black' }}
      >
        <ListItemText className={classes.listItemText} primary="Export Data" />
      </ListItem>
      <ListItem button>
        <ListItemText className={classes.listItemText}>
          <a style={{ color: 'black', textDecoration: 'none' }} href={LOGOUT_ENDPOINT}>Logout</a>
        </ListItemText>
      </ListItem>
    </List>
  </Drawer>
);

SideBar.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dataToExport: PropTypes.array.isRequired,
};

export default withStyles(styles)(SideBar);
