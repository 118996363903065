import { connect } from 'react-redux';
import { closeModal } from '../../modules/views';
import ProcessAPICallModal from './ProcessAPICallModal';

const mapStateToProps = (state) => {
  const { requestName, isOpen } = state.views.processAPICall;
  return {
    isOpen,
    isLoading: state.loading[requestName],
    error: state.error[requestName],
  };
};

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('processAPICall')),
});

const ProcessAPICallModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessAPICallModal);

export default ProcessAPICallModalContainer;
