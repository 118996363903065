import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { pick } from 'lodash';

import { FormikTextField, FormikDatetimeField } from '../FormikElements';
import { validationSchema, initialValues, FIELDS_TO_TRIM } from './formConfig';
import { trimValues } from '../../utils/helpers';

class FollowUpModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen, handleClose, followUp, isAPICallModalOpen,
    } = this.props;
    return (
      <Dialog
        keepMounted={isOpen}
        open={isOpen && !isAPICallModalOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => followUp({ ...values, ...trimValues(pick(values, FIELDS_TO_TRIM)) })}
        >
          <Form>
            <DialogTitle>Need to Follow-up</DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <FormikTextField
                    name="note"
                    fullWidth
                    multiline
                    placeholder="Client is not ready to schedule the engagement but is a fit for the On Demand Consulting. Will need to follow-up"
                    rows={4}
                    label="Please provide any additional information about the request"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikDatetimeField
                    disablePast
                    name="follow_up_date"
                    fullWidth
                    label="Follow up date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Note: An email alert will be sent to you 24 hours before reminding you to
                    follow-up
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  }
}

FollowUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  followUp: PropTypes.func.isRequired,
};

FollowUpModal.defaultProps = {
  isLoading: false,
  error: '',
};

export default FollowUpModal;
