import { connect } from 'react-redux';

import DeleteUserModal from './DeleteUserModal';
import { closeModal } from '../../modules/views';
import { deleteOne } from '../../modules/entities';
import { CRUD_USERS } from '../../config';

const mapStateToProps = state => ({
  isOpen: state.views.deleteUser.isOpen,
  isAPICallModalOpen: state.views.processAPICall.isOpen,
  id: state.views.deleteUser.id,
  isLoading: state.loading.deleteUsersEntity,
  error: state.error.deleteUsersEntity,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('deleteUser')),
  deleteUser: id => dispatch(
    deleteOne({
      entityType: 'users',
      entityUrl: `${CRUD_USERS}/${id}`,
      entityId: id,
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    deleteUser: () => dispatchProps.deleteUser(id),
  };
};

const DeleteUserModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DeleteUserModal);

export default DeleteUserModalContainer;
