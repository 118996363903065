import { createSelector } from 'reselect';
import moment from 'moment';

const getRequests = state => state.entities.requests.byId;

const requestsObjToArray = function requestsObjToArray(requestsObj) {
  return Object.keys(requestsObj).map(key => requestsObj[key]);
};

const getAccountIdFromInstanceId = function getAccountIdFromInstanceId(instanceId) {
  const regex = /a\/(.*?)(?=:)/gm;
  const matches = regex.exec(instanceId);
  return matches[1];
};

const removeCommas = function removeCommas(str) {
  return str.replace(/,/g, '');
};

export const getDataToExport = createSelector(
  [getRequests],
  requests => requestsObjToArray(requests).map(row => [
    row._id.slice(-4).toString(),
    row._id,
    removeCommas(row.client.company),
    removeCommas(row.location),
    row.offering,
    row.partNumber,
    `"${row.price_formatted}"`,
    row.request_date ? moment(row.request_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).format('YYYY-MM-DD') : 'n/a',
    row.scheduled_date ? moment(row.scheduled_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).format('YYYY-MM-DD') : 'n/a',
    row.terminated_date ? moment(row.terminated_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).format('YYYY-MM-DD') : 'n/a',
    row.display_status,
    getAccountIdFromInstanceId(row.instance_id) || row.instance_id,
    row.billing_status_endpoint,
    row.requested_quantity,
    row.billed_quantity,
    row.remaining_quantity,
    row.plan_id,
    row.subscriptions ? row.subscriptions.map(subscription => `ID: ${subscription.subscription_id} Part: ${subscription.part_number}`).join('; ') : '',
  ]),
);
