const styles = theme => ({
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  hideIcon: {
    display: 'none',
  },
  rootSelected: {
    '&$selected': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
  selected: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
  },
});

export default styles;
