const styles = theme => ({
  listItemTextDetails: {
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 6}px`,
  },
  historyBox: {
    maxHeight: '500px',
    overflowY: 'auto',
    margin: 0,
    paddingTop: 0,
  },
  tableRowContainer: {
    display: 'flex',
    backgroundColor: '#f5f7fa',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexWrap: 'wrap',
    width: '100%',
    minHeight: theme.spacing.unit * 6,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsContainer: {
    margin: 0,
    marginLeft: '-32px',
  },
});

export default styles;
