import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import {
  entitiesSaga, entitiesReducer, errorReducer, loadingReducer,
} from './modules/entities';
import viewsReducer from './modules/views';
import { billingReducer, billingSaga } from './modules/billing';
import { LOGOUT } from './modules/authentication';

export const history = createBrowserHistory();

// Combine reducers
const appReducer = combineReducers({
  entities: combineReducers({
    requests: entitiesReducer('requests'),
    users: entitiesReducer('users'),
  }),
  loading: loadingReducer,
  error: errorReducer,
  views: viewsReducer,
  billing: billingReducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// middlewares
let middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(routerMiddleware(history));

if (process.env.NODE_ENV !== 'production') {
  console.log('not prod');
  middlewares.push(require('redux-immutable-state-invariant').default());
  middlewares.push(require('redux-logger').logger);
}

middlewares = applyMiddleware(...middlewares);

// redux dev tools
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
middlewares = reduxDevTools
  ? compose(
    middlewares,
    reduxDevTools,
  )
  : middlewares;

export const store = createStore(rootReducer, middlewares);

// run sagas
sagaMiddleware.run(entitiesSaga);
sagaMiddleware.run(billingSaga);
