import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';

import MainContent from '../../components/MainContent';
import SideBar from '../../components/SideBar';
import { Table } from '../../components/Table';
import { archivedHeaders, rejectedHeaders } from '../../config';

class ArchivedRequests extends React.Component {
  componentDidMount() {
    const { fetchRequests } = this.props;
    fetchRequests();
  }

  render() {
    const {
      completedRequests, rejectedRequests, areRequestsLoading, error,
    } = this.props;
    const renderHelper = () => {
      if (error) {
        return <Typography variant="h6">{error}</Typography>;
      }
      if (areRequestsLoading || !completedRequests) {
        return <CircularProgress />;
      }
      return (
        <MainContent>
          <Typography variant="h6">Completed Requests</Typography>
          {completedRequests.length === 0 ? (
            <Typography>No requests</Typography>
          ) : (
            <Table
              name="completed"
              header={archivedHeaders('completed')}
              requests={completedRequests}
            />
          )}
          <br />
          <Typography variant="h6">Rejected Requests</Typography>
          {rejectedRequests.length === 0 ? (
            <Typography>No requests</Typography>
          ) : (
            <Table name="rejected" header={rejectedHeaders('rejected')} requests={rejectedRequests} />
          )}
        </MainContent>
      );
    };

    return (
      <React.Fragment>
        <SideBar />
        {renderHelper()}
      </React.Fragment>
    );
  }
}

ArchivedRequests.propTypes = {
  completedRequests: PropTypes.arrayOf(PropTypes.object),
  rejectedRequests: PropTypes.arrayOf(PropTypes.object),
  areRequestsLoading: PropTypes.bool,
  fetchRequests: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ArchivedRequests.defaultProps = {
  completedRequests: null,
  rejectedRequests: null,
  areRequestsLoading: false,
  error: '',
};

export default ArchivedRequests;
