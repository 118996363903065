import { createTheme } from '@material-ui/core/styles';
import button from './theme.button';
import palette from './theme.palette';
import typography from './theme.typography';
import svgIcon from './theme.svgIcon';
import dialogContent from './theme.dialogContent';

const theme = {
  palette: {
    ...palette,
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    useNextVariants: true,
  },
  spacing: 8,
  overrides: {
    MuiButton: {
      ...button,
    },
    MuiTypography: {
      ...typography,
    },
    MuiSvgIcon: {
      ...svgIcon,
    },
    MuiDialogContent: {
      ...dialogContent,
    },
  },
};

export default createTheme(theme);
