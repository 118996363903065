import axios from 'axios';

export function handleErrors(err) {
  throw err;
}

export function fetchOne(entityType, entityUrl, options) {
  return axios
    .get(entityUrl, { params: options })
    .then(response => response.data)
    .catch(handleErrors);
}

export function fetchMany(entityType, entityUrl, options) {
  return axios
    .get(entityUrl, { params: options, withCredentials: true })
    .then(response => response.data)
    .catch(handleErrors);
}

export function updateOne(entityType, entityUrl, attributes) {
  return axios
    .put(entityUrl, attributes)
    .then(response => response.data)
    .catch(handleErrors);
}

export function updateMany(entityType, entityUrl, entities) {
  return axios
    .put(entityUrl, entities)
    .then(response => response.data)
    .catch(handleErrors);
}

export function createOne(entityType, entityUrl, attributes) {
  return axios
    .post(entityUrl, attributes)
    .then(response => response.data)
    .catch(handleErrors);
}

export function createMany(entityType, entityUrl, values) {
  return axios
    .post(entityUrl, values)
    .then(response => response.data)
    .catch(handleErrors);
}

export function deleteOne(entityType, entityUrl) {
  return axios
    .delete(entityUrl)
    .then(response => response.data)
    .catch(handleErrors);
}

export default {
  fetchOne,
  fetchMany,
  updateOne,
  updateMany,
  createOne,
  createMany,
};
