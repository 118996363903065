const styles = {
  primary: {
    main: '#152935',
    dark: '#00000e',
    light: '#41515e',
  },
  secondary: {
    main: '#3d70b2',
    light: '#729ee4',
    dark: '#004582',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  common: {
    black: '#000',
    white: '#fff',
    gray: '#aeaeae',
  },
  grey: {},
  background: {
    default: '#f5f7fb',
  },
};

export default styles;
