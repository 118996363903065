import { connect } from 'react-redux';
import { pickBy, identity } from 'lodash';

import FollowUpModal from './FollowUpModal';
import { closeModal } from '../../modules/views';
import { updateOne } from '../../modules/entities';
import { CRUD_REQUESTS } from '../../config';

const mapStateToProps = state => ({
  isOpen: state.views.followUp.isOpen,
  isAPICallModalOpen: state.views.processAPICall.isOpen,
  id: state.views.followUp.id,
  isLoading: state.loading.updateRequestsEntity,
  error: state.error.updateRequestsEntity,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('followUp')),
  followUp: (values, id) => dispatch(
    updateOne({
      entityType: 'requests',
      entityUrl: `${CRUD_REQUESTS}/${id}/follow-up`,
      entityData: pickBy(values, identity),
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    followUp: values => dispatchProps.followUp(values, id),
  };
};

const FollowUpModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FollowUpModal);

export default FollowUpModalContainer;
