import { connect } from 'react-redux';
import { values } from 'lodash';

import { openModal } from '../../modules/views';
import { CRUD_USERS } from '../../config';
import { fetchMany } from '../../modules/entities';
import AdminSettings from './AdminSettings';

const mapStateToProps = (state) => {
  const areUsersLoading = state.loading.fetchUsersEntities;
  let users;
  if (areUsersLoading) {
    users = null;
  } else {
    users = values(state.entities.users.byId).map(user => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`,
    }));
  }
  const error = state.error.fetchUsersEntities;
  return {
    users,
    areUsersLoading,
    error,
  };
};

const mapDispatchToProps = dispatch => ({
  openAddOrEditModal: () => dispatch(openModal({ modalName: 'addOrEditUser' })),
  fetchUsers: () => dispatch(
    fetchMany({
      entityType: 'users',
      entityUrl: CRUD_USERS,
    }),
  ),
});

const AdminSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSettings);

export default AdminSettingsContainer;
