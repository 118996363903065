import { connect } from 'react-redux';

import DeleteRequestModal from './DeleteRequestModal';
import { closeModal } from '../../modules/views';
import { deleteOne } from '../../modules/entities';
import { CRUD_REQUESTS } from '../../config';

const mapStateToProps = state => ({
  isOpen: state.views.deleteRequest.isOpen,
  isAPICallModalOpen: state.views.processAPICall.isOpen,
  id: state.views.deleteRequest.id,
  isLoading: state.loading.deleteRequestsEntity,
  error: state.error.deleteRequestsEntity,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('deleteRequest')),
  deleteRequest: id => dispatch(
    deleteOne({
      entityType: 'requests',
      entityUrl: `${CRUD_REQUESTS}/${id}`,
      entityId: id,
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    deleteRequest: () => dispatchProps.deleteRequest(id),
  };
};

const DeleteRequestModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DeleteRequestModal);

export default DeleteRequestModalContainer;
