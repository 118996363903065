import {
  takeEvery, call, put, all,
} from 'redux-saga/effects';

import { requestSuccess } from './entities/receiving/actions';

import { workerHandleError } from './entities/receiving/sagas';
import * as api from '../utils/api';
import { CHECK_BILLING_ENDPOINT } from '../config';

const CHECK_BILLING_STATUS_REQUEST = 'CHECK_BILLING_STATUS_REQUEST';

export const checkBillingStatus = id => ({
  type: CHECK_BILLING_STATUS_REQUEST,
  id,
});

export function* workerGetBillingStatus(action) {
  try {
    const { id, type } = action;

    const url = `${CHECK_BILLING_ENDPOINT}/${id}`;
    const response = yield call(api.fetchOne, null, url);
    yield put(requestSuccess({ request: type, response }));
  } catch (error) {
    const { type } = action;
    yield call(workerHandleError, { request: type, errorResponse: error.response });
  }
}

// Watcher root saga
export function* billingSaga() {
  yield all([takeEvery(CHECK_BILLING_STATUS_REQUEST, workerGetBillingStatus)]);
}

export const billingReducer = (state = null, action) => {
  const { type, response } = action;
  if (type !== 'CHECK_BILLING_STATUS_SUCCESS') {
    return state;
  }
  return response;
};
