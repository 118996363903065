import moment from 'moment';
import {
  omitBy, isEqual, get, toUpper, trim, mapValues,
} from 'lodash';

export const getDifferentAttributes = ({ initialValues, values }) => omitBy(values, (value, key) => isEqual(initialValues[key], value));

const desc = (a, b, orderBy) => {
  const first = toUpper(trim(get(a, orderBy)));
  const second = toUpper(trim(get(b, orderBy)));
  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  if (orderBy !== 'client.company' && a.client && a.client.company && b.client && b.client.company) {
    return b.client.company.localeCompare(a.client.company);
  }
  if (orderBy === 'client.company') {
    return b.offering.localeCompare(a.offering);
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getSorting = (order, orderBy) => (order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy));

export const setTimeToCurrent = (dateMoment) => {
  const now = moment();
  return dateMoment.set({
    hour: now.get('hour'),
    minute: now.get('minute'),
    second: now.get('second'),
  });
};

export const trimValues = object => mapValues(object, trim);

const joinArrays = (data, headers) => [headers, ...data].map(row => row.join(','))
  .join('\n');

export const arraysToCSV = (data, headers) => {
  const csv = joinArrays(data, headers);
  return `data:text/csv;charset=utf-8,${csv}`;
};
