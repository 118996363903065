import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { pick } from 'lodash';
import moment from 'moment';

import { FormikTextField, FormikDateField } from '../FormikElements';
import { initialValues, FIELDS_TO_TRIM } from './formConfig';
import { trimValues } from '../../utils/helpers';
import styles from './styles';

class CompleteModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen,
      handleClose,
      completeEngagement,
      isAPICallModalOpen,
      validationSchema,
      remaining_quantity,
      classes,
    } = this.props;
    return (
      <Dialog
        keepMounted={isOpen}
        open={isOpen && !isAPICallModalOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            const {
              note, terminated_date, new_scheduled_date, quantity_to_bill,
            } = values;
            const new_remaining_quantity = remaining_quantity - values.quantity_to_bill;
            completeEngagement({
              note, terminated_date, scheduled_date: new_remaining_quantity === 0 ? '' : new_scheduled_date, quantity_to_bill, ...trimValues(pick(values, FIELDS_TO_TRIM)),
            });
          }}
          render={({ values }) => (
            <Form>
              <DialogTitle>Complete Engagement</DialogTitle>
              <DialogContent>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="note"
                      fullWidth
                      multiline
                      placeholder="Client was extremely happy with what was delivered"
                      rows={4}
                      label="Please provide any additional information about the request"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikDateField
                      className={classes.engagementCompletedDate}
                      name="terminated_date"
                      minDate={moment().local().startOf('day').subtract(2, 'days')
                        .format('MM/DD/YYYY')}
                      disableFuture
                      fullWidth
                      label="Engagement Completed On (required)"
                    />
                  </Grid>
                  <Grid item xs={6} />
                  {remaining_quantity > 1 && (
                    <Fragment>
                      <Grid item xs={6}>
                        <FormikTextField name="quantity_to_bill" fullWidth label="Quantity to Bill (required)" type="number" inputProps={{ min: '0', max: remaining_quantity, step: '1' }} />
                      </Grid>
                      <Grid item container xs={6} alignItems="center">
                        {(values.quantity_to_bill >= 0 && ((remaining_quantity - values.quantity_to_bill) >= 0)) && (
                          <Typography>
                            {`New Remaining Quantity: ${remaining_quantity - values.quantity_to_bill}`}
                          </Typography>
                        )}
                      </Grid>
                      {!(values.quantity_to_bill > 1 && ((remaining_quantity - values.quantity_to_bill) === 0)) && (
                        <Fragment>
                          <Grid item xs={6}>
                            <FormikDateField
                              name="new_scheduled_date"
                              fullWidth
                              label="Next Engagement Scheduled for (required)"
                            />
                          </Grid>
                          <Grid item xs={6} />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  <Grid item xs={6} />
                  <Grid item xs={12}>
                    <Typography className={classes.note}>
                      NOTE: Usage must be submitted within 48 hours of completion. By completing this engagement, you will bill the customer&apos;s IBM Cloud subscription account for the services provided.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={!(values.terminated_date && moment().startOf('day').subtract(2, 'days').valueOf() <= values.terminated_date.valueOf())}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        />
      </Dialog>
    );
  }
}

CompleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  completeEngagement: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  remaining_quantity: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

CompleteModal.defaultProps = {
  isLoading: false,
  validationSchema: null,
  error: '',
};

export default withStyles(styles)(CompleteModal);
