import {
  string, object, array, bool,
} from 'yup';

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  team: '',
  products: [],
  locations: [],
  suppress_emails: false,
  will_receive_bss_failure_emails: false,
};

export const FIELDS_TO_TRIM = ['firstName', 'lastName', 'email'];

export const validationSchema = object().shape({
  firstName: string()
    .trim()
    .required('Field is required'),
  lastName: string()
    .trim()
    .required('Field is required'),
  email: string()
    .trim()
    .email('Email is invalid')
    .required('Field is required'),
  team: string().required('Field is required'),
  products: array().required('Field is required'),
  locations: array().required('Field is required'),
  suppress_emails: bool(),
  will_receive_bss_failure_emails: bool(),
});
