import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Paper,
  Divider,
  withStyles,
} from '@material-ui/core';
import { values as getValues } from 'lodash';
import { Formik, Form } from 'formik';

import MainContent from '../../components/MainContent';
import SideBar from '../../components/SideBar';
import { TableHeader } from '../../components/Table';
import OverflowMenu from '../../components/OverflowMenu';
import { FormikMultipleSelect } from '../../components/FormikElements';
import { adminsTableHeaders } from '../../config';
import { stableSort, getSorting } from '../../utils/helpers';
import styles from './styles';
import { validationSchema } from './formConfig';

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    const order = {};
    getValues(adminsTableHeaders).forEach(({ id }) => {
      order[id] = 'asc';
    });
    this.state = {
      order,
      orderBy: 'name',
    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  handleRequestSort(orderBy) {
    const { order } = this.state;
    const newOrder = order[orderBy] === 'desc' ? 'asc' : 'desc';
    this.setState({ order: { ...order, [orderBy]: newOrder }, orderBy });
  }

  render() {
    const {
      error, areUsersLoading, users, openAddOrEditModal, classes,
    } = this.props;
    const { order, orderBy } = this.state;

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (areUsersLoading || !users) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <SideBar />
        <MainContent>
          <Grid container justifyContent="space-between" className={classes.header}>
            <Grid item>
              <Typography variant="h6">Team Members</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={openAddOrEditModal}>
                Add Team Member
              </Button>
            </Grid>
          </Grid>
          <React.Fragment>
            <TableHeader
              header={getValues(adminsTableHeaders)}
              onRequestSort={this.handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            {stableSort(users, getSorting(order[orderBy], orderBy)).map(user => (
              <Formik
                key={user._id}
                validationSchema={validationSchema}
                initialValues={user}
                enableReinitialize
              >
                <Form>
                  <Paper square>
                    <div className={classes.tableRowContainer}>
                      <div className={classes.tableRow}>
                        <div className={classes.cell} style={{ width: adminsTableHeaders.name.width }}>
                          <Typography className={classes.breakWords}>{user.name}</Typography>
                        </div>
                        <div className={classes.cell} style={{ width: adminsTableHeaders.email.width }}>
                          <Typography className={classes.breakWords}>{user.email}</Typography>
                        </div>
                        <div className={classes.cell} style={{ width: adminsTableHeaders.team.width }}>
                          <Typography className={classes.breakWords}>{user.team}</Typography>
                        </div>
                        <div className={classes.cell} style={{ width: adminsTableHeaders.products.width }}>
                          {user.products && <FormikMultipleSelect disabled name="products" />}
                        </div>
                        <div className={classes.cell} style={{ width: adminsTableHeaders.locations.width }}>
                          {user.products && <FormikMultipleSelect disabled name="locations" />}
                        </div>
                      </div>
                      <OverflowMenu id={user._id} tableType="users" />
                    </div>
                    <Divider />
                  </Paper>
                </Form>
              </Formik>
            ))}
          </React.Fragment>
        </MainContent>
      </React.Fragment>
    );
  }
}

AdminSettings.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  areUsersLoading: PropTypes.bool,
  error: PropTypes.string,
  openAddOrEditModal: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

AdminSettings.defaultProps = {
  users: null,
  areUsersLoading: false,
  error: '',
};

export default withStyles(styles)(AdminSettings);
