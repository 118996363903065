import { connect } from 'react-redux';

import { openModal } from '../../modules/views';
import OverflowMenu from './OverflowMenu';

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  openModal: ({ modalName, id }) => dispatch(openModal({ modalName, id })),
});

const OverflowMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverflowMenu);

export default OverflowMenuContainer;
