import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import { FormikTextField } from '../FormikElements';
import { validationSchema, initialValues } from './formConfig';

class RejectModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen, handleClose, reject, isAPICallModalOpen,
    } = this.props;
    return (
      <Dialog
        keepMounted={isOpen}
        open={isOpen && !isAPICallModalOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => reject(values)}
        >
          <Form>
            <DialogTitle>Reject Request</DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <FormikTextField
                    name="note"
                    fullWidth
                    multiline
                    placeholder="Client accidentally selected the engagement. Not interested in pursuing"
                    rows={4}
                    label="Please provide any additional information about the request"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  }
}

RejectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
};

RejectModal.defaultProps = {
  isLoading: false,
  error: '',
};

export default RejectModal;
