import { connect } from 'react-redux';
import { pickBy, identity } from 'lodash';

import ScheduleModal from './ScheduleModal';
import { closeModal } from '../../modules/views';
import { updateOne } from '../../modules/entities';
import { CRUD_REQUESTS } from '../../config';
import { initialValues } from './formConfig';

const mapStateToProps = (state) => {
  const { id } = state.views.schedule;
  const scheduled_date = id ? state.entities.requests.byId[id].scheduled_date : '';
  return {
    isOpen: state.views.schedule.isOpen,
    isAPICallModalOpen: state.views.processAPICall.isOpen,
    id,
    isLoading: state.loading.updateRequestsEntity,
    error: state.error.updateRequestsEntity,
    initialValues: { ...initialValues, scheduled_date: scheduled_date || '' },
  };
};

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('schedule')),
  scheduleRequest: (values, id) => dispatch(
    updateOne({
      entityType: 'requests',
      entityUrl: `${CRUD_REQUESTS}/${id}/schedule`,
      entityData: pickBy(values, identity),
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    scheduleRequest: values => dispatchProps.scheduleRequest(values, id),
  };
};

const ScheduleModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ScheduleModal);

export default ScheduleModalContainer;
