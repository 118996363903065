import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { SuccessIcon, ErrorIcon } from '../Icons';

import styles from './styles';

class ProcessAPICallModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      isOpen, isLoading, handleClose, error,
    } = this.props;
    if (isOpen && !error && prevProps.isLoading === true && isLoading === false) {
      setTimeout(handleClose, 500);
    }
  }

  render() {
    const {
      isOpen, handleClose, isLoading, error,
    } = this.props;

    const renderHelper = () => {
      if (isLoading) {
        return <CircularProgress size={200} />;
      }
      if (error) {
        return (
          <React.Fragment>
            <Typography variant="h6">{error}</Typography>
            <ErrorIcon />
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <Typography variant="h6">{error}</Typography>
          <SuccessIcon />
        </React.Fragment>
      );
    };

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={isLoading}
        transitionDuration={0}
      >
        <DialogContent>{renderHelper()}</DialogContent>
        {!isLoading && error && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}
ProcessAPICallModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

ProcessAPICallModal.defaultProps = {
  error: '',
  isLoading: false,
};
export default withStyles(styles)(ProcessAPICallModal);
