import { string, date, object } from 'yup';

const today = new Date();

export const FIELDS_TO_TRIM = ['note'];

export const initialValues = {
  note: '',
  follow_up_date: '',
};

export const validationSchema = object().shape({
  note: string(),
  follow_up_date: date()
    .min(today, 'Date and time cannot be ealier than now')
    .required('Field is required'),
});
