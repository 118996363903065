import { connect } from 'react-redux';
import { pick, keys } from 'lodash';

import AddOrEditUserModal from './AddOrEditUserModal';
import { closeModal } from '../../modules/views';
import { updateOne, createOne } from '../../modules/entities';
import { CRUD_USERS } from '../../config';
import { INITIAL_VALUES } from './formConfig';

const mapStateToProps = (state) => {
  const { id } = state.views.addOrEditUser;
  return {
    isOpen: state.views.addOrEditUser.isOpen,
    id,
    isAPICallModalOpen: state.views.processAPICall.isOpen,
    isLoading: state.loading.updateUsersEntity || state.loading.createUsersEntity,
    error: state.error.updateUsersEntity || state.error.createUsersEntity,
    initialValues: id ? pick(state.entities.users.byId[id], keys(INITIAL_VALUES)) : INITIAL_VALUES,
  };
};

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('addOrEditUser')),
  updateUser: (values, id) => {
    dispatch(
      updateOne({
        entityType: 'users',
        entityUrl: `${CRUD_USERS}/${id}`,
        entityData: values,
      }),
    );
  },
  createUser: values => dispatch(
    createOne({
      entityType: 'users',
      entityUrl: CRUD_USERS,
      entityData: values,
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  const updateUser = values => dispatchProps.updateUser(values, id);
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    createOrUpdateUser: id ? updateUser : dispatchProps.createUser,
  };
};

const AddOrEditUserModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AddOrEditUserModal);

export default AddOrEditUserModalContainer;
