import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  ListItem,
  Collapse,
  IconButton,
  Grid,
  Divider,
  Paper,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { get } from 'lodash';

import OverflowMenu from '../../OverflowMenu';
import styles from './styles';
import { PAYMENT_METHODS } from '../../../config';

const renderDateHelper = (date, dateFormat) => (date ? moment(date).format(dateFormat) : 'Not scheduled');

class TableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  }

  render() {
    const {
      classes, header, request, updateSubscriptionExpDate,
    } = this.props;
    const { isExpanded } = this.state;
    let subscription_expiration_date = moment(request.subscription_expiration_date);
    subscription_expiration_date = subscription_expiration_date.isValid()
      ? subscription_expiration_date.startOf('day')
      : null;
    return (
      <Paper square>
        <div className={classes.tableRowContainer}>
          <IconButton onClick={this.toggleCollapse}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <div className={classes.tableRow}>
            {(header && header.length) && header.map(({ width, id, dateFormat }) => (
              <div key={id} className={classes.cell} style={{ width }}>
                <Typography>
                  {dateFormat
                    ? renderDateHelper(get(request, id), dateFormat)
                    : get(request, id)}
                </Typography>
              </div>
            ))}
          </div>
          <OverflowMenu id={request._id} tableType="requests" request={request} />
        </div>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <ListItem className={classes.listItemTextDetails}>
            <Grid className={classes.detailsContainer} container spacing={8}>
              <Grid container item xs={3} spacing={8} alignContent="flex-start">
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Full Request ID</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span>{request._id.slice(0, -4)}</span>
                      <span style={{ fontWeight: 'bold' }}>{request._id.slice(-4)}</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Account ID</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {request.instance_id.match(/a\/([^:]*):/)[1]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Contact Information</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <i>Tel: </i>
                      {request.client.phone_number}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <i>Email: </i>
                      {request.client.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Subscription Details</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {request.subscriptions && request.subscriptions.map(subscription => (
                        <span key={subscription.subscription_id}>
                          <span style={{ fontWeight: 'bold' }}>Subscription ID: </span>
                          <span>{subscription.subscription_id}</span>
                          <br />
                          <span style={{ fontWeight: 'bold' }}>Part Number: </span>
                          <span>{subscription.part_number}</span>
                          <br />
                          <br />
                        </span>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
                {request.payment_method === PAYMENT_METHODS.BILL_MY_ACCOUNT && (
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography color="textSecondary">Subscription Expiration Date</Typography>
                    </Grid>
                    <Grid item>
                      <DatePicker
                        name="subscription_expiration_date"
                        format="MM/DD/YYYY"
                        margin="none"
                        value={subscription_expiration_date || null}
                        onChange={(date) => {
                          if (!date.isSame(subscription_expiration_date)) {
                            updateSubscriptionExpDate({
                              subscription_expiration_date: date,
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Requested Quantity</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {request.requested_quantity || 1}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={3} spacing={8} alignContent="flex-start">
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="textSecondary">Questionnaire Responses</Typography>
                  </Grid>
                  {request && request.questionnaire_responses && request.questionnaire_responses.map((response, index) => {
                    if (response && !response.match(/:\s*$/)) {
                      return (
                        <Grid item key={response}>
                          <Typography>{`${index + 1}) ${response}`}</Typography>
                        </Grid>
                      );
                    }
                    return '';
                  })}
                </Grid>
              </Grid>
              <Grid container xs={6} spacing={8} className={classes.historyBox} alignContent="flex-start">
                <Grid item>
                  <Typography color="textSecondary">History </Typography>
                </Grid>
                <Grid item container>
                  {request.history.map((entry, index) => (
                    <Grid container key={`${entry.timestamp}_${index}`}>
                      <Typography>
                        {`- ${moment(entry.timestamp).format('MM/DD/YYYY')}`}
                        {entry.user && ' - '}
                        {entry.user && <strong>{`${entry.user}`}</strong>}
                        {` - ${entry.actions}`}
                        {entry.note && <i>{` - Note: ${entry.note}`}</i>}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container item xs={6} alignContent="flex-start">
                  <Grid item>
                    <Typography color="textSecondary">Billing Status</Typography>
                  </Grid>
                  <Grid item container>
                    {request.billing_status ? (
                      <Typography>
                        <strong>{request.billing_status.state}</strong>
                        {' '}
                        -
                        {request.billing_status.received_on}
                      </Typography>
                    ) : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </Collapse>
        <Divider />
      </Paper>
    );
  }
}
TableRow.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  request: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  updateSubscriptionExpDate: PropTypes.func.isRequired,
};

export default withStyles(styles)(TableRow);
