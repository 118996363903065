import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

class DeleteRequestModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen, handleClose, deleteRequest, isAPICallModalOpen,
    } = this.props;
    return (
      <Dialog keepMounted={isOpen} open={isOpen && !isAPICallModalOpen} onClose={handleClose}>
        <DialogTitle>Delete Request</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this request from the system? There will be no way of
            recovering the data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteRequest} variant="contained" color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
};

DeleteRequestModal.defaultProps = {
  isLoading: false,
  error: '',
};

export default DeleteRequestModal;
