import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControlLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import { Field } from 'formik';

const WrappedMuiCheckbox = ({
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  helperText,
  disabled,
  ...others
}) => (
  <FormControl error={errors[name] && touched[name]}>
    <FormControlLabel
      control={(
        <Checkbox
          id={name}
          checked={value || false}
          value={name}
          onChange={(event) => {
            setFieldValue(name, event.target.checked);
          }}
          disabled={disabled}
          color="secondary"
        />
)}
      {...others}
    />
    {!!helperText && <FormHelperText error={false}>{helperText}</FormHelperText>}
    {errors[name] && touched[name] && <FormHelperText>{errors[name]}</FormHelperText>}
  </FormControl>
);

const FormikCheckbox = ({ name, ...others }) => (
  <Field name={name} component={WrappedMuiCheckbox} {...others} />
);

WrappedMuiCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.arrayOf(PropTypes.bool).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  helperText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

WrappedMuiCheckbox.defaultProps = {
  disabled: false,
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FormikCheckbox.defaultProps = {
  disabled: false,
};

export default FormikCheckbox;
