import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, SvgIcon } from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';

import styles from './styles';

const SuccessIconCreator = ({ classes }) => (
  <SvgIcon className={classes.icon} color="primary">
    <CheckCircle />
  </SvgIcon>
);

SuccessIconCreator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SuccessIcon = withStyles(styles)(SuccessIconCreator);

const ErrorIconCreator = ({ classes }) => (
  <SvgIcon className={classes.icon} color="error">
    <Error />
  </SvgIcon>
);

ErrorIconCreator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ErrorIcon = withStyles(styles)(ErrorIconCreator);
