import { connect } from 'react-redux';

import TableRow from './TableRow';
import { updateOne } from '../../../modules/entities';
import { CRUD_REQUESTS } from '../../../config';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSubscriptionExpDate: entityData => dispatch(
    updateOne({
      entityType: 'requests',
      entityUrl: `${CRUD_REQUESTS}/${ownProps.request._id}/subscription-expiration`,
      entityData,
    }),
  ),
});

const TableRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableRow);

export default TableRowContainer;
