import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const WrappedMuiTextField = ({
  field: { value, name, ...otherFieldProps }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...others
}) => (
  <TextField
    variant="filled"
    id={name}
    name={name}
    value={value || ''}
    error={errors[name] && touched[name]}
    helperText={errors[name] && touched[name] && errors[name]}
    {...otherFieldProps}
    {...others}
  />
);

WrappedMuiTextField.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

WrappedMuiTextField.defaultProps = {
  type: 'text',
  children: null,
};

const FormikTextField = ({ name, ...others }) => (
  <Field name={name} component={WrappedMuiTextField} {...others} />
);

WrappedMuiTextField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
};

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikTextField;
