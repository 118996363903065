import {
  string, date, object, number,
} from 'yup';
import moment from 'moment';

const today = moment(new Date()).local();

export const initialValues = {
  note: '',
  terminated_date: '',
  new_scheduled_date: '',
  quantity_to_bill: '',
};

export const FIELDS_TO_TRIM = ['note', 'quantity_to_bill'];

export const createValidationSchema = (scheduled_date, remaining_quantity) => object().shape({
  note: string(),
  terminated_date: date()
    .max(today, 'Date and time cannot be later than now')
    .min(
      moment(scheduled_date)
        .local()
        .startOf('day')
        .format(),
      `Date cannot be earlier than the scheduled date of ${moment(scheduled_date).format(
        'MM/DD/YYYY',
      )}`,
    )
    .required('Field is required'),
  new_scheduled_date: date()
    .when('terminated_date', (terminated_date, schema) => terminated_date ? schema.min(terminated_date, 'Scheduled Date must be no earlier than selected Completion Date') : schema)
    .when('quantity_to_bill', (quantity_to_bill, schema) => (remaining_quantity - quantity_to_bill > 0) ? schema.required('Field is required') : schema),
  quantity_to_bill: (remaining_quantity > 1 ? number().required('Field is required') : number()).min(1, 'Quantity to Bill must be at least 1').max(remaining_quantity, `Quantity to Bill cannot be more than ${remaining_quantity}`),
});
