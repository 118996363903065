const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    overflowX: 'auto',
    minWidth: '900px',
  },
  toolbar: {
    minHeight: '50px',
  },
});

export default styles;
