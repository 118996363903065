import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, TablePagination, Paper } from '@material-ui/core';

import styles from './styles';

const TablePaginationComponent = ({
  rowsPerPage,
  page,
  count,
  classes,
  onChangeRowsPerPage,
  onChangePage,
}) => (
  <Paper square>
    <TablePagination
      classes={{ toolbar: classes.paginationBox }}
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
    />
  </Paper>
);

TablePaginationComponent.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TablePaginationComponent);
