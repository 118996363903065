import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';

import MainContent from '../../components/MainContent';
import SideBar from '../../components/SideBar';
import { Table } from '../../components/Table';
import { createHeaders } from '../../config';

class Requests extends React.Component {
  componentDidMount() {
    const { fetchRequests } = this.props;
    fetchRequests();
  }

  render() {
    const {
      newRequests, approvedRequests, areRequestsLoading, error,
    } = this.props;
    const renderHelper = () => {
      if (error) {
        return <Typography variant="h6">{error}</Typography>;
      }
      if (areRequestsLoading || !newRequests) {
        return <CircularProgress />;
      }
      return (
        <MainContent>
          <Typography variant="h6">New Requests</Typography>
          {newRequests.length === 0 ? (
            <Typography>No requests</Typography>
          ) : (
            <Table name="new" header={createHeaders('new')} requests={newRequests} />
          )}
          <br />
          <Typography variant="h6">Approved Requests</Typography>
          {approvedRequests.length === 0 ? (
            <Typography>No requests</Typography>
          ) : (
            <Table name="approved" header={createHeaders('approved')} requests={approvedRequests} />
          )}
        </MainContent>
      );
    };

    return (
      <React.Fragment>
        <SideBar />
        {renderHelper()}
      </React.Fragment>
    );
  }
}

Requests.propTypes = {
  newRequests: PropTypes.arrayOf(PropTypes.object),
  approvedRequests: PropTypes.arrayOf(PropTypes.object),
  areRequestsLoading: PropTypes.bool,
  fetchRequests: PropTypes.func.isRequired,
  error: PropTypes.string,
};

Requests.defaultProps = {
  newRequests: null,
  approvedRequests: null,
  areRequestsLoading: false,
  error: '',
};

export default Requests;
