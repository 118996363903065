// server routes
export const LOGOUT_ENDPOINT = '/auth/logout';
export const LOGIN_ENDPOINT = '/auth/login';
export const CRUD_REQUESTS = '/api/requests';
export const CRUD_USERS = '/api/users';
export const CHECK_BILLING_ENDPOINT = '/api/billing';

// ui routes
const PREFIX = '';
export const LANDING_ENDPOINT = `${PREFIX}/`;
export const REQUESTS_ENDPOINT = `${PREFIX}/requests`;
export const ARCHIVED_REQUESTS_ENDPOINT = `${PREFIX}/archivedRequests`;
export const ADMIN_SETTINGS_ENDPOINT = `${PREFIX}/adminSettings`;

// Data to Export
export const CSV_HEADERS = [
  'ID (Last 4 of Full Request ID)',
  'Full Request ID',
  'Company',
  'Location',
  'Offering',
  'Part Number',
  'Price',
  'Request Date',
  'Scheduled Date',
  'Terminated Date',
  'Status',
  'Account ID',
  'Requested Quantity',
  'Billed Quantity',
  'Remaining Quantity',
  'Plan ID',
  'Subscriptions',
  'Billing Status Endpoint',
];

// dropdown options
export const teamDropdownOptions = [
  {
    id: 'IBM Cloud Garage',
    label: 'IBM Cloud Garage',
  },
  {
    id: 'Watson Expert Services',
    label: 'Watson Expert Services',
  },
];

/**
 * List of Offerings shown in the Admin Settings dialogs
 * id - References the "offering" field of the Requests collection when populating the Requests lists
 * label - User-facing text shown in the Admin Settings dialogs (drop-downs, etc)
 */
export const offeringsDropdownOptions = [
  {
    id: 'Data and AI Learn (2 Days)',
    label: 'Data and AI Learn (2 Days)',
  },
  {
    id: 'Data and AI Learn (4 Days)',
    label: 'Data and AI Learn (4 Days)',
  },
  {
    id: 'Data and AI On Demand Consulting (Premium)',
    label: 'Data and AI On Demand Consulting (Premium)',
  },
  {
    id: 'Data and AI On Demand Consulting (Standard)',
    label: 'Data and AI On Demand Consulting (Standard)',
  },
  {
    id: 'Architect Services (2 days) - Hyper Protect Crypto Services',
    label: 'Architect Services (2 days) - Hyper Protect Crypto Services',
  },
  {
    id: 'Architect Services (2 days) - High Performance Compute',
    label: 'Architect Services (2 days) - High Performance Compute',
  },
  {
    id: 'Architect Services (2 days) - IBM Cloud Satellite',
    label: 'Architect Services (2 days) - IBM Cloud Satellite',
  },
  {
    id: 'Architect Services (2 days) - Cloud Native Development - IKS and ROKS',
    label: 'Architect Services (2 days) - Cloud Native Development - IKS and ROKS',
  },
  {
    id: 'Architect Services (2 days) - Security and Compliance Center',
    label: 'Architect Services (2 days) - Security and Compliance Center',
  },
  {
    id: 'Architect Services (2 days) - VMware',
    label: 'Architect Services (2 days) - VMware',
  },
  {
    id: 'Architect Services (2 days) - Other',
    label: 'Architect Services (2 days) - Other',
  },
  {
    id: 'Architect Services - Hyper Protect Crypto Services',
    label: 'Architect Services - Hyper Protect Crypto Services',
  },
  {
    id: 'Architect Services - High Performance Compute',
    label: 'Architect Services - High Performance Compute',
  },
  {
    id: 'Architect Services - IBM Cloud Satellite',
    label: 'Architect Services - IBM Cloud Satellite',
  },
  {
    id: 'Architect Services - Cloud Native Development - IKS and ROKS',
    label: 'Architect Services - Cloud Native Development - IKS and ROKS',
  },
  {
    id: 'Architect Services - Security and Compliance Center',
    label: 'Architect Services - Security and Compliance Center',
  },
  {
    id: 'Architect Services - VMware',
    label: 'Architect Services - VMware',
  },
  {
    id: 'Architect Services - Other',
    label: 'Architect Services - Other',
  },
  {
    id: 'Build Services - Hyper Protect Crypto Services',
    label: 'Build Services - Hyper Protect Crypto Services',
  },
  {
    id: 'Build Services - High Performance Compute',
    label: 'Build Services - High Performance Compute',
  },
  {
    id: 'Build Services - IBM Cloud Satellite',
    label: 'Build Services - IBM Cloud Satellite',
  },
  {
    id: 'Build Services - Cloud Native Development - IKS and ROKS',
    label: 'Build Services - Cloud Native Development - IKS and ROKS',
  },
  {
    id: 'Build Services - Security and Compliance Center',
    label: 'Build Services - Security and Compliance Center',
  },
  {
    id: 'Build Services - VMware',
    label: 'Build Services - VMware',
  },
  {
    id: 'Build Services - Other',
    label: 'Build Services - Other',
  },
  {
    id: 'Expertise Connect for IBM Cloud',
    label: 'Expertise Connect for IBM Cloud',
  },
  {
    id: 'Build SAP HANA Starter Edition on IBM Power',
    label: 'Build SAP HANA Starter Edition on IBM Power',
  },
  {
    id: 'Migrate Workloads to IBM Power Virtual Server',
    label: 'Migrate Workloads to IBM Power Virtual Server',
  },
  {
    id: 'Migrate Workloads to IBM Power Virtual Server - Additional LPAR',
    label: 'Migrate Workloads to IBM Power Virtual Server - Additional LPAR',
  },
  {
    id: 'LPAR',
    label: 'LPAR',
  },
  {
    id: 'Build IBM Storage Protect Solution for IBM Power Virtual Server',
    label: 'Build IBM Storage Protect Solution for IBM Power Virtual Server',
  },
  {
    id: 'Expertise Connect for IBM Power',
    label: 'Expertise Connect for IBM Power',
  },
];

export const locationsDropdownOptions = [
  {
    id: 'Americas',
    label: 'Americas',
  },
  {
    id: 'Asia Pacific',
    label: 'Asia Pacific',
  },
  {
    id: 'Europe',
    label: 'Europe',
  },
];

export const PAYMENT_METHODS = {
  BILL_MY_ACCOUNT: 'Bill IBM Cloud Account',
};

const customFields = {
  new: {
    date: {
      id: 'request_date',
      width: '14.4%',
      label: 'Submitted Date',
      canSort: true,
      dateFormat: 'MMMM DD, YYYY',
    },
    quantity: {
      id: 'requested_quantity',
      width: '11%',
      label: 'Requested Quantity',
      canSort: true,
    },
  },
  approved: {
    date: {
      id: 'scheduled_date',
      width: '14.4%',
      label: 'Scheduled For',
      canSort: true,
      dateFormat: 'MMMM DD, YYYY @ h:mm:ss a',
    },
    quantity: {
      id: 'remaining_quantity',
      width: '11%',
      label: 'Remaining Quantity',
      canSort: true,
    },
  },
  completed: {
    date: {
      id: 'terminated_date',
      width: '14.4%',
      label: 'Completion Date',
      canSort: true,
      dateFormat: 'MMMM DD, YYYY',
    },
    quantity: {
      id: 'billed_quantity',
      width: '11%',
      label: 'Billed Quantity',
      canSort: true,
    },
  },
  rejected: {
    date: {
      id: 'terminated_date',
      width: '14.4%',
      label: 'Rejection Date',
      canSort: true,
      dateFormat: 'MMMM DD, YYYY',
    },
    quantity: {
      id: 'requested_quantity',
      width: '11%',
      label: 'Requested Quantity',
      canSort: true,
    },
  },
};

export const archivedHeaders = name => [
  {
    id: 'lastFourOfId',
    width: '6%',
    label: 'ID',
    canSort: true,
  },
  {
    id: 'client.company',
    width: '20%',
    label: 'Company',
    canSort: true,
  },
  {
    id: 'offering',
    width: '25%',
    label: 'Requested Offering',
    canSort: true,
  },
  customFields[name].quantity,
  {
    id: 'location',
    width: '12%',
    label: 'Delivery Location',
    canSort: true,
  },
  customFields[name].date,
  {
    id: 'billing_status.state',
    width: '8%',
    label: 'Billing Status',
    canSort: true,
  },
];

export const rejectedHeaders = name => [
  {
    id: 'lastFourOfId',
    width: '6%',
    label: 'ID',
    canSort: true,
  },
  {
    id: 'client.company',
    width: '20%',
    label: 'Company',
    canSort: true,
  },
  {
    id: 'offering',
    width: '25%',
    label: 'Requested Offering',
    canSort: true,
  },
  customFields[name].quantity,
  {
    id: 'location',
    width: '12%',
    label: 'Delivery Location',
    canSort: true,
  },
  customFields[name].date,
];

export const createHeaders = name => [
  {
    id: 'lastFourOfId',
    width: '6%',
    label: 'ID',
    canSort: true,
  },
  {
    id: 'client.name',
    width: '14.2%',
    label: 'Name',
    canSort: true,
  },
  {
    id: 'client.company',
    width: '14.2%',
    label: 'Company',
    canSort: true,
  },
  {
    id: 'offering',
    width: '19.4%',
    label: 'Requested Offering',
    canSort: true,
  },
  customFields[name].quantity,
  {
    id: 'location',
    width: '12.2%',
    label: 'Delivery Location',
    canSort: true,
  },
  customFields[name].date,
  {
    id: 'display_status',
    width: '8%',
    label: 'Status',
    canSort: true,
  },
];

export const adminsTableHeaders = {
  name: {
    id: 'name',
    width: '12%',
    label: 'Name',
    canSort: true,
  },
  email: {
    id: 'email',
    width: '15%',
    label: 'Email',
    canSort: true,
  },
  team: {
    id: 'team',
    width: '12%',
    label: 'Team',
    canSort: true,
  },
  products: {
    id: 'products',
    width: '45%',
    label: 'Offerings',
    canSort: false,
  },
  locations: {
    id: 'locations',
    width: '16%',
    label: 'Delivery Location',
    canSort: false,
  },
};

export const getOverflowMenuOptions = ({ request, tableType }) => {
  if (tableType === 'users') {
    return [
      {
        id: 'addOrEditUser',
        label: 'Edit',
      },
      {
        id: 'deleteUser',
        label: 'Delete',
      },
    ];
  }
  switch (request.status) {
    case 'new':
      return [
        {
          id: 'approve',
          label: 'Approve',
        },
        {
          id: 'followUp',
          label: 'Follow-up',
        },
        {
          id: 'reject',
          label: 'Reject',
        },
      ];
    case 'approved': {
      const options = [
        {
          id: 'followUp',
          label: 'Follow-up',
        },
        {
          id: 'schedule',
          label: 'Schedule/Reschedule',
        },
      ];
      if (request.scheduled_date) {
        options.push({
          id: 'complete',
          label: 'Complete',
        });
      }
      options.push({
        id: 'reject',
        label: 'Reject',
      });
      return options;
    }
    case 'completed': {
      const options = [];
      if (request.payment_method === PAYMENT_METHODS.BILL_MY_ACCOUNT) {
        options.push({
          id: 'checkBillingStatus',
          label: 'Check Billing Status',
        });
      }
      options.push({
        id: 'deleteRequest',
        label: 'Delete',
      });
      return options;
    }
    case 'rejected':
      return [
        {
          id: 'deleteRequest',
          label: 'Delete',
        },
      ];
    case 'user':
      return [
        {
          id: 'addOrEditUser',
          label: 'Edit',
        },
        {
          id: 'deleteUser',
          label: 'Delete',
        },
      ];
    default:
      return [];
  }
};
