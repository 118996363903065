const styles = theme => ({
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexWrap: 'wrap',
    width: '100%',
    padding: `0px ${theme.spacing.unit * 6}px`,
    minHeight: theme.spacing.unit * 6,
  },
  sortingIcon: {
    padding: 0,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: 'min-content',
  },
});

export default styles;
