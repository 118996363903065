import { connect } from 'react-redux';
import { pickBy, identity, get } from 'lodash';

import CompleteModal from './CompleteModal';
import { closeModal } from '../../modules/views';
import { updateOne } from '../../modules/entities';
import { CRUD_REQUESTS } from '../../config';
import { createValidationSchema } from './formConfig';

const mapStateToProps = (state) => {
  const { id } = state.views.complete;
  const remaining_quantity = get(state, `entities.requests.byId.${id}.remaining_quantity`) || 1;
  const validationSchema = id
    ? createValidationSchema(state.entities.requests.byId[id].scheduled_date, remaining_quantity)
    : null;
  return {
    isOpen: state.views.complete.isOpen,
    id,
    isAPICallModalOpen: state.views.processAPICall.isOpen,
    isLoading: state.loading.updateRequestsEntity,
    error: state.error.updateRequestsEntity,
    validationSchema,
    remaining_quantity,
  };
};
const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('complete')),
  completeEngagement: (values, id) => dispatch(
    updateOne({
      entityType: 'requests',
      entityUrl: `${CRUD_REQUESTS}/${id}/complete`,
      entityData: pickBy(values, identity),
    }),
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    completeEngagement: values => dispatchProps.completeEngagement(values, id),
  };
};

const CompleteModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CompleteModal);

export default CompleteModalContainer;
