import palette from './theme.palette';

const styles = {
  colorTextPrimary: {
    color: palette.common.white,
  },
  colorTextSecondary: {
    color: palette.secondary.main,
    fontWeight: 'bold',
  },
};

export default styles;
