import { connect } from 'react-redux';

import CheckBillingModal from './CheckBillingModal';
import { closeModal } from '../../modules/views';
import { checkBillingStatus } from '../../modules/billing';

const mapStateToProps = state => ({
  isOpen: state.views.checkBillingStatus.isOpen,
  id: state.views.checkBillingStatus.id,
  isLoading: state.loading.checkBillingStatus,
  error: state.error.checkBillingStatus,
  response: state.billing,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModal('checkBillingStatus')),
  checkBillingStatus: id => dispatch(checkBillingStatus(id)),
});

// TODO: fix billing path
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    checkBillingStatus: () => dispatchProps.checkBillingStatus(id),
  };
};

const CheckBillingModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CheckBillingModal);

export default CheckBillingModalContainer;
