import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '../TableRow';
import TablePagination from '../TablePagination';
import TableHeader from '../TableHeader';
import { stableSort, getSorting } from '../../../utils/helpers';

class Table extends React.Component {
  constructor(props) {
    super(props);
    const order = {};
    props.header.forEach(({ id }) => {
      order[id] = 'asc';
    });
    this.state = {
      order,
      orderBy: 'client.name',
      page: 0,
      rowsPerPage: 5,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  componentDidMount() {
    const { name } = this.props;
    let cachedValues = localStorage.getItem(name);
    if (cachedValues) {
      cachedValues = JSON.parse(cachedValues);
      this.setState({ rowsPerPage: cachedValues.rowsPerPage });
    }
  }

  handleRequestSort(orderBy) {
    const { order } = this.state;
    const newOrder = order[orderBy] === 'desc' ? 'asc' : 'desc';
    this.setState({ order: { ...order, [orderBy]: newOrder }, orderBy });
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    const { name } = this.props;
    this.setState({ rowsPerPage: event.target.value });
    localStorage.setItem(name, JSON.stringify({ rowsPerPage: event.target.value }));
  }

  render() {
    const { requests, header, name } = this.props;
    const {
      page, order, orderBy, rowsPerPage,
    } = this.state;

    return (
      <React.Fragment>
        <TableHeader
          header={header}
          name={name}
          onRequestSort={this.handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
        {stableSort(requests, getSorting(order[orderBy], orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(request => (
            <TableRow key={request._id} header={header} request={request} name={name} />
          ))}
        <TablePagination
          rowsPerPage={rowsPerPage}
          page={page}
          count={requests.length}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

Table.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
};

export default Table;
