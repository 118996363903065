import React from 'react';
import { Typography } from '@material-ui/core';

import MainContent from '../../components/MainContent';

const PageNotFound = () => (
  <MainContent>
    <Typography variant="h4">Page not found</Typography>
  </MainContent>
);

export default PageNotFound;
