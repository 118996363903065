const styles = theme => ({
  header: {
    height: '50px',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBar: {
    minHeight: '50px',
    backgroundColor: theme.palette.primary.main,
  },
});

export default styles;
