import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

class DeleteUserModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      handleClose, isLoading, error, isOpen,
    } = this.props;
    if (isOpen && prevProps.isLoading === true && isLoading === false && !error) {
      handleClose();
    }
  }

  render() {
    const {
      isOpen, handleClose, deleteUser, isAPICallModalOpen,
    } = this.props;
    return (
      <Dialog keepMounted={isOpen} open={isOpen && !isAPICallModalOpen} onClose={handleClose}>
        <DialogTitle>Delete Team Member</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this team member?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUser} variant="contained" color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAPICallModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

DeleteUserModal.defaultProps = {
  isLoading: false,
  error: '',
};

export default DeleteUserModal;
